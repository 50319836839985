import React from 'react'
import { Theme } from 'templates/theme'
import ModuleListItem from './ModuleListItem'
import { shouldShowJeffWilhelm } from 'components/themes/template-components/ModuleItems'

interface Props {
	data: Theme
}

// Had to make this a class component to be able to use the react-to-print package
class ModuleListCard extends React.Component<Props> {
	render() {
		const { data } = this.props
		return (
			<div className="relative rounded bg-white shadow-lg">
				<div className="flex flex-col px-10">
					<span className="my-4 text-center text-3xl font-medium">{data.unit === '3' ? 'Literature Genre Lab Module' : 'Research Lab Module'}</span>
					<div className="mb-6 text-center text-lg text-slate-400">
						{data.unit === '3'
							? 'The Literature Genre Lab Block Module includes the following:'
							: 'The Research Lab Integrated Literacy Block Module includes the following:'}
					</div>
					<hr className="w-full" />
					{data.gradeLevel === 'K' ? (
						// Grade K List
						<ul className="list-checkmark mx-auto my-8">
							<ModuleListItem text="Teacher Guide" subText="1 Teacher Copy" />
							<ModuleListItem text="Science Tools" subText="30 Student Copies" />
							<ModuleListItem text="Big Books" subText="4-7 Titles" />
							<ModuleListItem text="Notebook" subText="30 Student Copies" />
							<ModuleListItem text="Read-Aloud Collection" subText="2 Baskets" />
							<ModuleListItem text="Thematic Library" subText="75 Classroom Titles" />
						</ul>
					) : (
						// Other Grades List
						<ul className="list-checkmark mx-auto my-8">
							<ModuleListItem text="Teacher Guide" subText="1 Teacher Copy" />
							{data.graphicOrganizer && <ModuleListItem text="Graphic Organizer" subText="30 Student Copies" />}
							{data.finalProjectOrganizer && <ModuleListItem text="Final Project Organizer" subText="1 Teacher Copy" />}
							{data.researchCard && <ModuleListItem text="Research Cards" subText="30 Student Copies" />}
							{(data.unit === '2' || data.unit === '4') && <ModuleListItem text="Writing Cards" subText="30 Student Copies" />}
							{data.readAloudCollection && <ModuleListItem text="Read-Aloud Collection" subText="1 Basket" />}
							{data.anchorTitles && <ModuleListItem text="Anchor Titles" subText="2-5 Titles" />}
							{data.coreText && <ModuleListItem text="Core Text(s)" subText="30 Student Copies Each" />}
							{data.notebook && <ModuleListItem text="Notebook" subText="30 Student Copies" />}
							{data.informationalTextSet && <ModuleListItem text="Informational Text Set" subText="1 Basket" />}
							{data.unit === '3' ? (
								<ModuleListItem text="Thematic Research Library" subText="80 Classroom Titles" />
							) : (
								<ModuleListItem text="Thematic Research Library" subText="100 Classroom Titles" />
							)}
							{shouldShowJeffWilhelm(data) && <ModuleListItem text="Text by Jeffrey Wilhelm" subText="1 Teacher Copy" />}
						</ul>
					)}
				</div>
				<img src="/images/utils/blobs.svg" alt="card blobs" />
			</div>
		)
	}
}

export default ModuleListCard
