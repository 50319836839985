import Modal from 'components/global/Modal'
import { Col, Row } from 'components/grid'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

import MaterialButton from './MaterialButton'
import formatUpdatedAtDate from 'utils/formatUpdatedAtDate'

interface Props {
	images: { id: string; gatsbyImageData: IGatsbyImageData; updatedAt: string }[]
}

const FinalProjectOrganizer = ({ images }: Props) => {
	const [show, setShow] = React.useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const data = useStaticQuery(graphql`
		query {
			contentfulModal(name: { eq: "Final Project Organizer" }) {
				title
				description {
					description
				}
			}
		}
	`)

	const title = data.contentfulModal.title
	const description = data.contentfulModal.description.description

	return (
		<React.Fragment>
			<MaterialButton onClick={handleShow} image={images[0].gatsbyImageData} title={title} />
			<Modal title={title} subTitle={images[1].updatedAt && `Updated ${formatUpdatedAtDate(images[1].updatedAt)}`} isOpen={show} handleClose={handleClose} wide>
				<Row>
					<Col>
						<p className="text-lg text-slate-400">{description}</p>
					</Col>
					<Col>
						<GatsbyImage image={images[1].gatsbyImageData} alt={title} />
					</Col>
				</Row>
			</Modal>
		</React.Fragment>
	)
}

export default FinalProjectOrganizer
