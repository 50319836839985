import Modal from 'components/global/Modal'
import { Col, Row } from 'components/grid'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

interface Props {
	baskets: {
		id: string
		title: string
		gatsbyImageData: IGatsbyImageData
	}[]
	researchCard?: IGatsbyImageData
	showTitle?: boolean
	informationalTextSet?: IGatsbyImageData
	unit?: '2' | '3' | '4'
}

const LeveledTextSet = ({ baskets, researchCard, showTitle, informationalTextSet, unit }: Props) => {
	const [show, setShow] = React.useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const data = useStaticQuery(graphql`
		query {
			contentfulModal(name: { eq: "Leveled Text Set" }) {
				title
				description {
					description
				}
			}
		}
	`)

	const title = data.contentfulModal.title
	const description = data.contentfulModal.description.description

	return (
		<React.Fragment>
			<button onClick={handleShow} className="flex h-full flex-col">
				{researchCard && (
					<div className="mb-6 w-full">
						<GatsbyImage
							image={researchCard}
							alt={title}
							style={{ maxHeight: 155 }}
							className="mx-auto transform transition duration-200 ease-in-out hover:scale-105"
							objectFit="contain"
						/>
					</div>
				)}
				<div className="flex transform px-3 transition duration-200 ease-in-out hover:scale-105">
					{unit === '3' ? (
						<Row>
							{baskets.slice(0, 4).map((basket) => {
								return (
									<Col width="w-1/5" gutter="none" key={basket.id}>
										<GatsbyImage image={basket.gatsbyImageData} alt={title} />
									</Col>
								)
							})}
							{informationalTextSet ? (
								<Col width="w-1/5" gutter="none">
									<GatsbyImage image={informationalTextSet} alt={title} />
								</Col>
							) : null}
						</Row>
					) : // Check if grade is kindergarten
					baskets.length === 3 ? (
						<Row>
							{baskets.map((basket) => {
								return (
									<Col width="w-1/3" gutter="none" key={basket.id}>
										<GatsbyImage image={basket.gatsbyImageData} alt={title} />
									</Col>
								)
							})}
						</Row>
					) : (
						<Row>
							{baskets.slice(0, 5).map((basket) => {
								return (
									<Col width="w-1/5" gutter="none" key={basket.id}>
										<GatsbyImage image={basket.gatsbyImageData} alt={title} />
									</Col>
								)
							})}
						</Row>
					)}
				</div>
				{showTitle ? (
					<div className="mt-auto w-full pt-4">
						<h5 className="text-center hover:underline">{title}</h5>
					</div>
				) : null}
			</button>
			<Modal title={title} isOpen={show} handleClose={handleClose}>
				<Row>
					<Col>
						<p className="text-lg text-slate-400">{description}</p>
					</Col>
					{researchCard && (
						<Col className="mb-4">
							<GatsbyImage image={researchCard} alt={title} className="mx-auto" objectFit="contain" style={{ maxHeight: 400 }} />
						</Col>
					)}
					<Col>
						{unit === '3' ? (
							<Row>
								{baskets.slice(0, 4).map((basket) => {
									return (
										<Col width="w-1/5" gutter="none" key={basket.id}>
											<GatsbyImage image={basket.gatsbyImageData} alt={title} />
										</Col>
									)
								})}
								{informationalTextSet ? (
									<Col width="w-1/5" gutter="none">
										<GatsbyImage image={informationalTextSet} alt={title} />
									</Col>
								) : null}
							</Row>
						) : // Check if kindergarten
						baskets.length === 3 ? (
							<Row>
								{baskets.map((basket) => {
									return (
										<Col width="w-1/3" gutter="none" key={basket.id}>
											<GatsbyImage image={basket.gatsbyImageData} alt={title} />
										</Col>
									)
								})}
							</Row>
						) : (
							<Row>
								{baskets.slice(0, 5).map((basket) => {
									return (
										<Col width="w-1/5" gutter="none" key={basket.id}>
											<GatsbyImage image={basket.gatsbyImageData} alt={title} />
										</Col>
									)
								})}
							</Row>
						)}
					</Col>
				</Row>
			</Modal>
		</React.Fragment>
	)
}

export default LeveledTextSet

LeveledTextSet.defaultProps = {
	showTitle: true
}
