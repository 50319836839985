import Modal from 'components/global/Modal'
import { Col, Row } from 'components/grid'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import MaterialButton from './MaterialButton'
import { ModalQuery } from 'common-types'
import formatUpdatedAtDate from 'utils/formatUpdatedAtDate'

interface Props {
	unit: number
	language: 'english' | 'spanish'
}

const WritingCards = ({ unit, language = 'english' }: Props) => {
	const [show, setShow] = React.useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	console.log(unit, language)

	const data = useStaticQuery<ModalQuery>(graphql`
		query {
			contentfulModal(name: { eq: "Writing Cards" }) {
				title
				image {
					title
					updatedAt
					gatsbyImageData(placeholder: BLURRED)
				}
				description {
					description
				}
			}
		}
	`)

	const title = data.contentfulModal.title
	const description = data.contentfulModal.description.description

	let image
	if (unit === 2 && language === 'english') image = data.contentfulModal.image[0]
	else if (unit === 4 && language === 'english') image = data.contentfulModal.image[1]
	else if (unit === 2 && language === 'spanish') image = data.contentfulModal.image[2]
	else image = data.contentfulModal.image[3]

	return (
		<React.Fragment>
			<MaterialButton onClick={handleShow} image={image.gatsbyImageData} title={title} />
			<Modal title={title} subTitle={`Updated ${formatUpdatedAtDate(image.updatedAt)}`} isOpen={show} handleClose={handleClose}>
				<Row>
					<Col>
						<p className="text-lg text-slate-400">{description}</p>
					</Col>
					<Col>
						<GatsbyImage image={image.gatsbyImageData} alt={title} />
					</Col>
				</Row>
			</Modal>
		</React.Fragment>
	)
}

export default WritingCards
