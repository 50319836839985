import SectionTitle from 'components/global/SectionTitle'
import { Col, Row, Section } from 'components/grid'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { Theme } from 'templates/theme'

interface Props {
	data: Theme
}

const LeveledLibrary = ({ data }: Props) => {
	return (
		<Section gray>
			<SectionTitle
				title="Thematic Research Library"
				text="Students use the Thematic Research Library to research their individual topics using the best informational books drawn from more than 250 publishers."
			/>
			<Row center className="px-4">
				{data.leveledLibrary &&
					data.leveledLibrary.slice(0, data.unit === '3' ? 4 : 5).map((basket) => {
						return (
							<Col width="w-1/5" key={basket.id} gutter="none" className="text-center">
								<GatsbyImage image={basket.gatsbyImageData} alt={basket.title} />
							</Col>
						)
					})}
			</Row>
			<Row>
				<Col>
					<p className="mt-3 text-center text-xs text-gray-600 sm:text-sm">
						* All materials shown are a representative sample. Actual titles may vary based on availability.
					</p>
				</Col>
			</Row>
		</Section>
	)
}

export default LeveledLibrary
