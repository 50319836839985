import LeveledTextSet from 'components/arc-core/LeveledTextSet'
import MaterialItem from 'components/arc-core/MaterialItem'
import { Col, Row } from 'components/grid'
import React from 'react'
import { Theme } from 'templates/theme'

interface Props {
	data: Theme
}

const ALaCarteItems = ({ data }: Props) => {
	return (
		<Row center>
			{data.name === 'Entomology' || data.name === 'Entomología' ? null : (
				<Col width="w-full md:w-1/2 lg:w-1/3" className="mb-6">
					<div className="h-full rounded bg-white shadow-lg">
						<div className="flex flex-col p-6">
							<span className="text-center text-2xl font-medium">Individual Basket</span>
							<p className="text-center text-lg text-slate-400">
								{data.gradeLevel === 'K' || data.name === 'Profesiones en mi comunidad' ? '25 titles' : '20 titles'}
							</p>
							<hr className="mb-6" />
							{data.leveledLibrary && <MaterialItem image={data.leveledLibrary.slice(0, 1)[0].gatsbyImageData} name="Leveled Library" showTitle={false} />}
						</div>
					</div>
				</Col>
			)}
			{data.gradeLevel === 'K' ? (
				<Col width="w-full md:w-1/2 lg:w-1/3" className="mb-6">
					<div className="h-full rounded bg-white shadow-lg">
						<div className="flex flex-col p-6">
							<span className="text-center text-2xl font-medium">Text Set</span>
							<p className="text-center text-lg text-slate-400">75 titles</p>
							<hr className="mb-6" />
							{data.leveledLibrary && <LeveledTextSet showTitle={false} baskets={data.leveledLibrary} />}
						</div>
					</div>
				</Col>
			) : (
				<Col width="w-full md:w-1/2 lg:w-1/3" className="mb-6">
					<div className="h-full rounded bg-white shadow-lg">
						<div className="flex flex-col p-6">
							<span className="text-center text-2xl font-medium">Thematic Research Library</span>
							<p className="text-center text-lg text-slate-400">{data.unit === '3' && !data.informationalTextSet ? '80 titles' : '100 titles'}</p>
							<hr className="mb-6" />
							<LeveledTextSet
								showTitle={false}
								unit={data.unit}
								informationalTextSet={data.informationalTextSet?.gatsbyImageData}
								baskets={data.leveledLibrary}
								researchCard={data.researchCard?.gatsbyImageData}
							/>
						</div>
					</div>
				</Col>
			)}
			{data.unit !== '3' ? (
				<Col width="w-full md:w-1/2 lg:w-1/3" className="mb-6">
					<div className="h-full rounded bg-white shadow-lg">
						<div className="flex flex-col p-6">
							<span className="text-center text-2xl font-medium">Theme-Related Folders</span>
							<p className="text-center text-lg text-slate-400">30 copies</p>
							<hr className="mb-6" />
							{data.themeFolders && <MaterialItem image={data.themeFolders.gatsbyImageData} name="Theme Folders" showTitle={false} />}
						</div>
					</div>
				</Col>
			) : null}
			<Col width="w-full md:w-1/2 lg:w-1/3" className="mb-6">
				<div className="h-full rounded bg-white shadow-lg">
					<div className="flex flex-col p-6">
						<span className="text-center text-2xl font-medium">Student Blank Books</span>
						<p className="text-center text-lg text-slate-400">30 copies</p>
						<hr className="mb-6" />
						<MaterialItem name="Student Blank Books" showTitle={false} />
					</div>
				</div>
			</Col>
			<Col width="w-full md:w-1/2 lg:w-1/3" className="mb-6">
				<div className="h-full rounded bg-white shadow-lg">
					<div className="flex flex-col p-6">
						<span className="text-center text-2xl font-medium">Job Embedded PD</span>
						<p className="text-center text-lg text-slate-400">1 Day of Professional Learning</p>
						<hr className="mb-6" />
						<MaterialItem name="Professional Learning" showTitle={false} />
					</div>
				</div>
			</Col>
		</Row>
	)
}

export default ALaCarteItems
