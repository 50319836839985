import FinalProjectOrganizer from 'components/arc-core/FinalProjectOrganizer'
import GraphicOrganizer from 'components/arc-core/GraphicOrganizer'
import MaterialItem from 'components/arc-core/MaterialItem'
import ReadAloudCollectionTwo from 'components/arc-core/ReadAloudCollectionTwo'
import WritingCards from 'components/arc-core/WritingCards'
import { Col, Row, Section } from 'components/grid'
import React from 'react'
import { Theme } from 'templates/theme'

interface Props {
	data: Theme
}

export function shouldShowJeffWilhelm(data: Theme) {
	const gradesToHide = ['K', '1', '2', '3', '4', '5']
	const hasGrades = data.grades.map((g) => gradesToHide.includes(g))
	if (hasGrades.includes(true) && data.unit === '2') return false
	else return true
}

const ModuleItems = ({ data }: Props) => {
	return (
		<Section>
			<Row center>
				{data.frameworkCover && (
					<Col width="w-1/2 md:w-1/3" className="material-column">
						<MaterialItem name="Teacher Guide" image={data.frameworkCover.gatsbyImageData} />
					</Col>
				)}
				{data.researchCard && (
					<Col width="w-1/2 md:w-1/3" className="material-column">
						<MaterialItem name={data.unit === '3' ? 'Genre Card' : 'Research Card'} image={data.researchCard.gatsbyImageData} />
					</Col>
				)}
				{data.graphicOrganizer && (
					<Col width="w-1/2 md:w-1/3" className="material-column">
						<GraphicOrganizer images={data.graphicOrganizer} />
					</Col>
				)}
				{data.finalProjectOrganizer && (
					<Col width="w-1/2 md:w-1/3" className="material-column">
						<FinalProjectOrganizer images={data.finalProjectOrganizer} />
					</Col>
				)}
				{data.gradeLevel === 'K' || data.unit === '3' ? null : (
					<Col width="w-1/2 md:w-1/3" className="material-column">
						<WritingCards language={data.themeType === 'Spanish' ? 'spanish' : 'english'} unit={parseInt(data.unit)} />
					</Col>
				)}
				{data.coreText && (
					<Col width="w-1/2 md:w-1/3" className="material-column">
						<MaterialItem name="Core Text" image={data.coreText.gatsbyImageData} />
					</Col>
				)}
				{data.notebook && (
					<Col width="w-1/2 md:w-1/3" className="material-column">
						<MaterialItem name="Notebook" image={data.notebook.gatsbyImageData} />
					</Col>
				)}
				{data.readAloudCollection && (
					<Col width="w-1/2 md:w-1/3" className="material-column">
						<ReadAloudCollectionTwo baskets={data.readAloudCollection} />
					</Col>
				)}
				{data.anchorTitles && (
					<Col width="w-1/2 md:w-1/3" className="material-column">
						<MaterialItem name="Anchor Titles" image={data.anchorTitles.gatsbyImageData} />
					</Col>
				)}
				{data.informationalTextSet && (
					<Col width="w-1/2 md:w-1/3" className="material-column">
						<MaterialItem name="Informational Text Set" image={data.informationalTextSet.gatsbyImageData} />
					</Col>
				)}
				{data.bigBooks && (
					<Col width="w-1/2 md:w-1/3" className="material-column">
						<MaterialItem name="Big Books" image={data.bigBooks.gatsbyImageData} />
					</Col>
				)}
				{data.scienceTools && (
					<Col width="w-1/2 md:w-1/3" className="material-column">
						<MaterialItem name="Science Tools" image={data.scienceTools.gatsbyImageData} />
					</Col>
				)}
				<Col width="w-1/2 md:w-1/3" className="material-column">
					<MaterialItem name="Professional Learning" />
				</Col>
				{shouldShowJeffWilhelm(data) && (
					<Col width="w-1/2 md:w-1/3" className="material-column">
						<MaterialItem name="Jeffrey Wilhelm Stack" />
					</Col>
				)}
			</Row>
		</Section>
	)
}

export default ModuleItems
