import SectionTitle from 'components/global/SectionTitle'
import { Col, Row, Section } from 'components/grid'
import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Theme } from 'templates/theme'

import ALaCarteItems from './ALaCarteItems'
import ModuleListCard from './ModuleListCard'

interface Props {
	data: Theme
}

const WhatsIncluded = ({ data }: Props) => {
	const componentRef = useRef(null)
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		removeAfterPrint: true
	})

	return (
		<Section gray>
			<SectionTitle title="What's Included in this Module?" />
			<Row className="mb-20 justify-center">
				<Col width="w-full sm:w-5/6 md:w-3/4 lg:w-1/2 xl:w-5/12" className="mb-5">
					<div className="flex justify-end">
						<button onClick={handlePrint}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="relative right-[12px] top-[35px] z-50 h-6 w-6 text-slate-300"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fillRule="evenodd"
									d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
									clipRule="evenodd"
								/>
							</svg>
						</button>
					</div>
					<ModuleListCard data={data} ref={componentRef} />
				</Col>
			</Row>
			<SectionTitle title="À la carte Items" text="Leveled Baskets and Text Sets are available to purchase separately from the full Research Lab module." />
			<ALaCarteItems data={data} />
		</Section>
	)
}

export default WhatsIncluded
