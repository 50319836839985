import LargeForm from 'components/forms/LargeForm'
import Head from 'components/global/Head'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import LeveledLibrary from 'components/themes/template-components/LeveledLibrary'
import ModuleItems from 'components/themes/template-components/ModuleItems'
import TemplateHeader from 'components/themes/template-components/TemplateHeader'
import WhatsIncluded from 'components/themes/template-components/WhatsIncluded'
import { graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

interface Props {
	data: ThemeQuery
}

export default function ThemeTemplate({ data: themeData }: Props) {
	const data = themeData.contentfulTheme

	return (
		<Layout>
			<Head title={`${data.name} | ARC Core Theme`} description={data.description?.description || ''} image={data.coverImage?.file.url.substring(2) || ''} />
			<TemplateHeader data={data} />
			<LeveledLibrary data={data} />
			<ModuleItems data={data} />
			<WhatsIncluded data={data} />
			<Section>
				<Row id="priceForm" className="justify-center">
					<Col width="w-full md:w-5/6 lg:w-3/4 xl:w-1/2">
						<LargeForm
							pageName={`${data.name} Theme Page`}
							title="Looking for Pricing?"
							text="Fill out the form below and you'll get access to our pricing list to compare our different products."
							showPricingGuide
						/>
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}

export interface Theme {
	name: string
	gradeLevel: string
	grades: string[]
	themeType: 'Research Lab' | 'Spanish' | 'Spanish Genre' | 'Genre' | 'ELA' | 'Text Set'
	themeSubject: 'Life Science' | 'Earth & Space Science' | 'Physical Science' | 'World History' | 'American History' | 'World Geography'
	schoolPaceConnectGrades?: string[]
	unit: '2' | '3' | '4'
	finalProjectOrganizer?: {
		id: string
		gatsbyImageData: IGatsbyImageData
		updatedAt: string
	}[]
	graphicOrganizer?: {
		id: string
		gatsbyImageData: IGatsbyImageData
		updatedAt: string
	}[]
	notebook?: {
		gatsbyImageData: IGatsbyImageData
	}
	coreText?: {
		gatsbyImageData: IGatsbyImageData
	}
	exemplarPack?: {
		gatsbyImageData: IGatsbyImageData
	}
	anchorTitles?: {
		gatsbyImageData: IGatsbyImageData
	}
	informationalTextSet?: {
		gatsbyImageData: IGatsbyImageData
	}
	logo?: {
		file: {
			url: string
		}
	}
	researchCard?: {
		gatsbyImageData: IGatsbyImageData
	}
	frameworkCover?: {
		gatsbyImageData: IGatsbyImageData
		file: {
			url: string
		}
	}
	coverImage?: {
		file: {
			url: string
		}
	}
	description?: {
		description: string
	}
	leveledLibrary: {
		id: string
		title: string
		gatsbyImageData: IGatsbyImageData
	}[]
	themeFolders?: {
		gatsbyImageData: IGatsbyImageData
	}
	scienceTools?: {
		gatsbyImageData: IGatsbyImageData
	}
	readAloudCollection?: {
		id: string
		gatsbyImageData: IGatsbyImageData
	}
	bigBooks?: {
		gatsbyImageData: IGatsbyImageData
	}
}

interface ThemeQuery {
	contentfulTheme: Theme
}

export const query = graphql`
	query ($slug: String!) {
		contentfulTheme(slug: { eq: $slug }) {
			name
			gradeLevel
			grades
			themeType
			themeSubject
			schoolPaceConnectGrades
			unit
			finalProjectOrganizer {
				id
				gatsbyImageData
				updatedAt
			}
			graphicOrganizer {
				id
				gatsbyImageData
				updatedAt
			}
			notebook {
				gatsbyImageData
			}
			coreText {
				gatsbyImageData
			}
			exemplarPack {
				gatsbyImageData
			}
			anchorTitles {
				gatsbyImageData
			}
			informationalTextSet {
				gatsbyImageData
			}
			logo {
				file {
					url
				}
			}
			researchCard {
				gatsbyImageData
			}
			frameworkCover {
				gatsbyImageData
				file {
					url
				}
			}
			coverImage {
				file {
					url
				}
			}
			description {
				description
			}
			leveledLibrary {
				id
				title
				gatsbyImageData
			}
			themeFolders {
				gatsbyImageData
			}
			scienceTools {
				gatsbyImageData
			}
			readAloudCollection {
				id
				gatsbyImageData
			}
			bigBooks {
				gatsbyImageData
			}
		}
	}
`
