import Modal from 'components/global/Modal'
import { Col, Row } from 'components/grid'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

import MaterialButton from './MaterialButton'

interface Props {
	image?: IGatsbyImageData
	name: string
	basket?: string
	showTitle?: boolean
	wide?: boolean
}

const MaterialItem = ({ image, name, basket, showTitle, wide }: Props) => {
	const [show, setShow] = React.useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const data = useStaticQuery<ModalQuery>(query).allContentfulModal.edges.filter((modal) => modal.node.name === name)[0].node

	const title = data.title
	const description = data.description.description
	if (!image) image = data.image[0].gatsbyImageData

	return (
		<React.Fragment>
			<MaterialButton onClick={handleShow} image={image} title={title} showTitle={showTitle} />
			<Modal title={title} isOpen={show} handleClose={handleClose} wide={wide}>
				<Row>
					<Col>
						<p className="text-lg text-slate-400">{description}</p>
					</Col>
					<Col>
						<GatsbyImage image={image} alt={title} className="mx-auto" style={basket ? { maxWidth: 400 } : {}} />
					</Col>
				</Row>
			</Modal>
		</React.Fragment>
	)
}

interface IModal {
	name: string
	title: string
	description: {
		description: string
	}
	image: {
		gatsbyImageData: IGatsbyImageData
	}[]
}

interface ModalQueryNode {
	node: IModal
}

interface ModalQuery {
	allContentfulModal: {
		edges: ModalQueryNode[]
	}
}

const query = graphql`
	query {
		allContentfulModal {
			edges {
				node {
					name
					title
					description {
						description
					}
					image {
						gatsbyImageData
					}
				}
			}
		}
	}
`

export default MaterialItem
