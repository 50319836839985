import { LitLab } from 'common-types'
import { HundredBookChallengeModule } from 'components/100-book-challenge/Modules'
import { Col } from 'components/grid'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Toolkit } from 'pages/intervention'
import React from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
	onClick?: () => void
	baskets: HundredBookChallengeModule['leveledLibrary'] | LitLab['hookBookLibrary'] | Toolkit['smallGroupTexts']
	title: string
	max?: number
	noZoom?: boolean
}

const MaterialButtonBaskets = ({ onClick, baskets, title, max, noZoom }: Props) => {
	return (
		<button onClick={onClick} className="flex h-full flex-col focus:outline-none">
			<div className={twMerge('mt-auto flex transform gap-3 px-3', !noZoom && 'transition duration-200 ease-in-out hover:scale-105')}>
				{baskets &&
					baskets
						.slice(0, max)
						.map((basket) => <GatsbyImage key={basket.id} image={basket.gatsbyImageData} alt={title} style={{ maxHeight: 250 }} objectFit="contain" />)}
			</div>
			<div className="mt-auto w-full pt-4">
				<span className={twMerge('inline-block w-full text-center text-xl font-semibold', onClick && 'hover:underline')}>{title}</span>
			</div>
		</button>
	)
}

export default MaterialButtonBaskets
