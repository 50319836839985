import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
	onClick?: () => void
	image?: IGatsbyImageData
	title: string
	showTitle?: boolean
	shadow?: boolean
	noZoom?: boolean
}

const MaterialButton = ({ onClick, image, title, showTitle = true, shadow, noZoom }: Props) => {
	return (
		<button onClick={onClick} className={twMerge('flex h-full flex-col items-center focus:outline-none', !onClick && 'cursor-default')}>
			{image && (
				<GatsbyImage
					image={image}
					alt={title}
					className={twMerge('mx-auto mt-auto w-auto transform transition duration-200 ease-in-out', !noZoom && 'hover:scale-105')}
					imgClassName={`${shadow ? 'shadow' : ''}`}
					style={{ maxHeight: 250 }}
					objectFit="contain"
				/>
			)}
			{showTitle && (
				<div className="mt-auto w-full pt-4">
					<span className={twMerge('inline-block w-full text-center text-xl font-semibold', onClick && 'hover:underline')}>{title}</span>
				</div>
			)}
		</button>
	)
}

export default MaterialButton
