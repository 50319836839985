import Modal from 'components/global/Modal'
import { Col, Row } from 'components/grid'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import MaterialButtonBaskets from './MaterialButtonBaskets'

const ReadAloudCollectionTwo = ({ baskets }) => {
	const [show, setShow] = React.useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const data = useStaticQuery(graphql`
		query {
			contentfulModal(name: { eq: "Read-Aloud Collection" }) {
				title
				description {
					description
				}
			}
		}
	`)

	const title = data.contentfulModal.title
	const description = data.contentfulModal.description.description

	return (
		<React.Fragment>
			<MaterialButtonBaskets onClick={handleShow} title={title} baskets={baskets} />
			<Modal title={title} isOpen={show} handleClose={handleClose}>
				<Row>
					<Col>
						<p className="text-slate-400 text-lg">{description}</p>
					</Col>
					<Col>
						<div className="flex px-3">
							{baskets.map((basket) => {
								return (
									<Col gutter="none" key={basket.id}>
										<GatsbyImage image={basket.gatsbyImageData} alt={title} />
									</Col>
								)
							})}
						</div>
					</Col>
				</Row>
			</Modal>
		</React.Fragment>
	)
}

export default ReadAloudCollectionTwo
